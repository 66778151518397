import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { Helmet } from 'react-helmet';
import './Article.css';
import Butter from 'buttercms';
const butter = Butter('3396c175e75d58965a68660f152690a24c9cbede');

class Article extends Component {

  constructor(props) {
    super(props);

    this.state = {
      meta: false,
      article: false
    };
  }

  componentDidMount() {
    let {
      slug
    } = this.props.match.params;

    butter.post.retrieve(slug).then(resp => resp.data).then(response => {
      this.setState({
        meta: response.meta,
        article: response.data
      })
    });
  }

  render() {
    let {
      article
    } = this.state;

    if (article === false) {
      return null;
    }

    return (
      <article role='main' className='Article-primary-content'>
        <Helmet>
          <title>{article.title} | andrew.lowther.name</title>
          <meta name='description' content={article.summary} />
        </Helmet>

        <header>
          <Link to='/'>&laquo; Home</Link>

          {article.featured_image ? (
            <div className='Article-featured-image'>
              <img src={article.featured_image} alt={article.title} />
            </div>
          ) : null}

          <h1>{article.title}</h1>
        </header>

        <div className='copy' dangerouslySetInnerHTML={{__html: article.body}} />

        {article.categories.length ? (
          <div className='Article-categories'>
            <h2>Categories</h2>
            {article.categories.map(category => {
              return (
                <span className='Article-category-pill' key={category.slug}>{category.name}</span>
              );
            })}
          </div>
        ) : null}

        {article.author ? (
          <div className='Article-author'>
            <h2>Author</h2>

            <img src={article.author.profile_image} alt={`${article.author.first_name} ${article.author.last_name}`}/>

            <p>
              {article.author.first_name} {article.author.last_name}
            </p>
          </div>
        ) : null}
      </article>
    );
  }

}

export default Article;
