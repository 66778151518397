import React, { Component } from 'react';

import Butter from 'buttercms';
import {Link} from "react-router-dom";
const butter = Butter('3396c175e75d58965a68660f152690a24c9cbede');

class ArticleListPage extends Component {

  constructor(props) {
    super(props);

    this.state = {
      page: "1",
      resp: false
    };
  }

  static getDerivedStateFromProps(props, state) {
    if (props.hasOwnProperty('match') === false) {
      return null;
    }
    if (props.match.params.page !== state.page) {
      return {
        page: props.match.params.page
      };
    }
    return null;
  }

  fetchPosts(page) {
    butter.post.list({
      page: page,
      page_size: 10
    }).then((resp) => {
      this.setState({
        resp: resp.data
      })
    });
  }

  componentDidMount() {
    let page = (this.props.hasOwnProperty('match') ? this.props.match.params.page : 1);

    this.fetchPosts(page)
  }

  componentDidUpdate(prevProps) {
    if (prevProps.hasOwnProperty('match') && prevProps.match.params.page !== this.state.page) {
      this.fetchPosts(this.state.page);
    }
  }

  render() {
    let {
      resp
    } = this.state;

    if (resp === false) {
      return null;
    }

    let articles = resp.data;
    let meta = resp.meta;

    return (
      <div className="App-articles">
        <nav role='navigation'>
          <ul>
            {articles.map(article => {
              return (
                <li key={article.url}>
                  <Link to={`/post/${article.url}`}>{article.title}</Link>
                </li>
              );
            })}
          </ul>
        </nav>

        <div className='pagination'>
          {meta.previous_page && <Link to={`/p/${meta.previous_page}`}>&laquo; Prev</Link>}

          {meta.next_page && <Link to={`/p/${meta.next_page}`}>Next &raquo;</Link>}
        </div>
      </div>
    );
  }

}

export default ArticleListPage;
