import React from 'react';
import { Link } from 'react-router-dom';

const Header = () => (
  <header className='App-header' role='banner'>
    <h1>
      <Link to={'/'}>andrew.lowther.name</Link>
    </h1>

    <nav role='navigation'>
      <a className='butter-cms' href='https://buttercms.com'>
        <img src='https://cdn.buttercms.com/JSSDbrHPSnGlLUcyHTn5' alt='Butter CMS'/>
      </a>
    </nav>
  </header>
);

export default Header;
