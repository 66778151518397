import React from 'react';
import { HashRouter as Router, Route } from 'react-router-dom';
import { Helmet } from 'react-helmet';
import Article from './Article';
import './App.css';

import ArticleListPage from "./ArticleListPage";
import Header from "./Header";
import Footer from "./Footer";

const App = () => (
  <Router>
    <div className='App'>

      <Helmet>
        <title>Homepage | andrew.lowther.name</title>
        <meta name='description' content='A senior web developer with something of a passion for emerging technologies' />
      </Helmet>

      <Header />

      <Route exact path='/' render={() => {
        return <ArticleListPage />
      }} />

      <Route path='/p/:page' component={ArticleListPage} />
      <Route path='/post/:slug' component={Article} />

      <Footer />
    </div>
  </Router>
);

export default App;
